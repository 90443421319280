import React from "react";

// import {longdo, map, LongdoMap} from '../../src/LongdoMap';
// import Mapacc from "./Mapacc";
//import Googlemap from "./Googlemap";

import { Link } from "react-router-dom";
import Mapaccs from "./Mapacc";

//footer-container
const Footer = () => {
  const showInMapClicked = () => {
    // window.open("https://maps.google.com?q=13.792046,100.617899");
    window.open(
      "https://maps.google.com?q=บริษัท แอ็คเค้าท์ติ้ง ซิสเต็ม จำกัด"
    );
  };

  return (
    <div className="footer">
      <div className="footer--full">
        <div className="footer--item">
          <ul className="list-unstyled">
            <li className="fw-bold">ติดต่อเรา</li>
            <li>บริษัท แอ็คเค้าท์ติ้ง ซิสเต็ม จำกัด</li>
            <li>313 ซอยลาดพร้าว87 (จันทราสุข) ถนนลาดพร้าว</li>
            <li>แขวงคลองเจ้าคุณสิงห์ เขตวังทองหลาง กทม. 10310</li>
            <li>เวลาทำการ วันจันทร์ - ศุกร์ เวลา 8:30 - 17:30 น.</li>
          </ul>

          <ul className="list-unstyled">
            <li>
              <i class="bi bi-telephone-outbound-fill icon-sm icon-white"></i>
              <span>&nbsp;02-9313100-2, 02-9313105-8</span>
            </li>
            <li>
              <i class="bi bi-building-fill-check icon-sm icon-white"></i>
              <span>&nbsp;02-9313103-4</span>
            </li>
            <li>
              <i class="bi bi-envelope-at-fill icon-sm icon-white"></i>
              <span>&nbsp;accsystemthai@gmail.com</span>
            </li>
            <li>
              <a href="http://line.me/ti/p/~accscan">
                <i class="bi bi-line icon-sm icon-white"></i>
                <span className="text-white">&nbsp;@accscan</span>
              </a>
            </li>
          </ul>

          <ul className="list-unstyled">
            <li className="footer-header fw-bold">ติดตามเรา</li>
            <li>
              <a href="https://www.facebook.com/profile.php?id=100083510220887">
                <i class="bi bi-facebook icon-sm icon-white"></i>
                <span className="text-white">&nbsp;Accounting System</span>
              </a>
            </li>
          </ul>
        </div>

        <div className="footer--item">
          {/* <ul className="list-unstyled">
            <li>
              <a
                href="https://www.google.com/maps/place/13%C2%B047'31.8%22N+100%C2%B036'58.5%22E/@13.7921667,100.61625,17z/data=!3m1!4b1!4m4!3m3!8m2!3d13.7921667!4d100.61625?entry=ttu
              AccTax @249ybgdf"
                target="_blank"
                className="h6 text-white"
              >
                <span className="text-white">แผนที่บริษัท / เส้นทาง</span>
              </a>              
            </li>
          </ul> */}

          <ul style={{ listStyle: "none" }}>
            <li>
              <a
                href="https://www.rd.go.th"
                target="_blank"
                className="text-white"
              >
                กรมสรรพากร
              </a>
            </li>
            <li>
              <a
                href="https://www.dbd.go.th"
                target="_blank"
                className="text-white"
              >
                กรมพัฒนาธุรกิจ
              </a>
            </li>
            <li>
              <a
                href="https://www.tfac.or.th"
                target="_blank"
                className="text-white"
              >
                สภาวิชาชีพบัญชี
              </a>
            </li>
            <li>
              <a
                href="https://www.deetookfree.com"
                target="_blank"
                className="text-white"
              >
                โปรแกรมถูก/ฟรี (ดีถูกฟรี)
              </a>
            </li>
          </ul>

          <hr />
          <span>การยื่นภาษี และงบการเงิน ที่ถูกต้อง</span>
          <ul style={{ listStyle: "none" }}>
            <li>
              <Link to="/files/PersonalIncomeTax.pdf" target="_blank">
                <span className="text-white">&nbsp;ภาษีเงินได้บุคคลธรรมดา</span>
              </Link>
            </li>
            <li>
              <Link to="https://cdn2.me-qr.com/pdf/21095074.pdf" target="_blank">
                <span className="text-white">&nbsp;ภาษีเงินได้นิติบุคคล</span>
              </Link>
            </li>
            <li>
              <Link to="/files/Balance.pdf" target="_blank">
                <span className="text-white font-weight-bold">
                  &nbsp;งบการเงินถูกต้อง
                </span>
              </Link>
            </li>
            {/* <li>
              <Link to="/files/Balance-com.pdf" target="_blank">
                <span className="text-white">&nbsp;รายการย่อที่ต้องมีในงบการเงิน ฉบับใหม่</span>
              </Link>
            </li> */}
          </ul>
        </div>

        <div className="footer--item2">
          <div>
            <div className="d-flex align-items-center justify-content-center">
              <img
                src="../images/qrcode_accs.png"
                className="acctax-logo img-fluid"
              ></img>
            </div>
            <div>ACCOUNTING SYSTEM</div>
          </div>
        </div>
      </div>

      {/* <div>
      <hr />
          <ul>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d242.17376073894118!2d100.61643955520934!3d13.79212137884495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29d001fc66737%3A0x4c558c39cb3515fb!2sAccounting%20System%20Co.%2CLtd.!5e0!3m2!1sen!2sth!4v1711429327729!5m2!1sen!2sth" className="map"></iframe>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d242.17372819980022!2d100.61602503061295!3d13.792152739779404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29d001fc66737%3A0x4c558c39cb3515fb!2sAccounting%20System%20Co.%2CLtd.!5e0!3m2!1sen!2sth!4v1711506764213!5m2!1sen!2sth" className="map"></iframe>
          </ul>
      </div> */}
    </div>
  );
};

export default Footer;
