import React from "react";
import HeaderBar from "./HeaderBar";
import CopyRight2 from "./CopyRight2";
import { Link } from "react-router-dom";
import CopyRight from "./CopyRight";

function Accnew() {
  return (
    <div>
      <HeaderBar />
      <div className="accnew-download">
        <div className="accnew-download-grid">
          <div>
            <i class="bi bi-arrow-right-square-fill h1"></i>&nbsp;&nbsp;
            โปรแกรม AccTax
            {/* <img src="../images/acctax-program-2.jpg" className="download-app"/> */}
          </div>
          <div>
            <i class="bi bi-cloud-download text-primary"></i>
            <Link to="/files/AccTax 1.1.rar" target="_blank" download>
              <span className="text-primary">&nbsp;Download</span>
            </Link>
          </div>
          <div className="d-flex align-items-center text-muted">
            <h6>
              วันที่ 1 กรกฎาคม 2567&nbsp;
              <span className="badge text-danger">Last Updated</span>
            </h6>
          </div>
        </div>
        <hr />
        <div className="accnew-download-grid">
          <div>
            <i class="bi bi-file-earmark-pdf-fill h1"></i>
            &nbsp;&nbsp;คู่มือโปรแกรม
            {/* <img src="../images/ACCTAX-manual-2.jpg" className="download-app"/> */}
          </div>
          <div>
            <i class="bi bi-cloud-download text-warning"></i>
            <Link to="/files/ACCTAX-MANUAL.pdf" target="_blank" download>
              <span className="text-warning">&nbsp;Download</span>
            </Link>
          </div>
          <div className="d-flex align-items-center text-muted">
            <h6>
            วันที่ 23 พฤษภาคม 2567&nbsp;
              <span className="badge text-danger">Last Updated</span>
            </h6>
          </div>
        </div>

        <hr />
        <div className="accnew-download-grid">
          <div>
            <i class="bi bi-file-earmark-ppt-fill h1"></i>
            &nbsp;&nbsp;รายงาน และอื่นๆ
            {/* <img src="../images/ACCTAX-report-2.jpg" className="download-app"/> */}
          </div>
          <div>
            <i class="bi bi-cloud-download text-success"></i>
            <Link to="/files/App-AccTax.rar" target="_blank" download>
              <span className="text-success">&nbsp;Download</span>
            </Link>
          </div>
          <div className="d-flex align-items-center text-muted">
            <h6>
            วันที่ 11 มิถุนายน 2567&nbsp;
              <span className="badge text-danger">Last Updated</span>
            </h6>
          </div>
        </div>

        <hr />
        <div className="accnew-download-grid">
          <div>
            <i class="bi bi-filetype-xlsx h1"></i>
            &nbsp;&nbsp;โปรแกรมจัดการไฟล์
            {/* <img src="../images/accfile-program-2.jpg" className="download-app"/> */}
          </div>
          <div>
            <i class="bi bi-cloud-download text-danger"></i>            
            <Link to="/files/AccFiles 1.1.rar" target="_blank" download>
              <span className="text-danger">&nbsp;Download</span>
            </Link>
          </div>
          <div className="d-flex align-items-center text-muted">
            <h6>
              วันที่ 11 มิถุนายน 2567&nbsp;
              <span className="badge text-danger">Last Updated</span>
            </h6>
          </div>
        </div>

        <div className="previous-intro">
          <Link to="/">
            <span>ย้อนกลับ</span>
          </Link>
        </div>
      </div>
      <CopyRight2 />
    </div>
  );
}

export default Accnew;
