import React from "react";
// import "../App.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab, far);

function Header() {
  return (
    <div className="headerFull">
      <header>
        <div className="headerFull-text">
          {/* <video className="video-first" autoPlay muted loop>
            <source src="Skyscrapers.mp4" type="video/mp4" />
          </video> */}

          {/* <p className="text-white">Accounting System</p>
          <p className="gradient-text">
            รับทำบัญชี ภาษี งบการเงิน ตรวจสอบบัญชี
          </p>
          <p className="gradient-text">
            โดยทีมงานที่มีคุณภาพ ความเชี่ยวชาญ และประสบการณ์สูง
          </p> */}
          {/* <p className="gradient-text">Grow Your</p>
          <p className="text-white">Business With</p>
          <p className="gradient-text">Accounting System</p> */}          
        </div>
      </header>
    </div>
  );
}

export default Header;
